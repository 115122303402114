import React, { useEffect  } from 'react';

const AdsComponent = () => {
    



    useEffect(() => {

        try {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        }

        catch (e) {

        }

    },[]);



    return (
        <>
            <ins class="adsbygoogle"
            style={{ display: "block" }}
                data-ad-client="ca-pub-5086704841008531"
                data-ad-slot="4480006065"
                data-ad-format="auto"
                data-full-width-responsive="true">
            </ins>
        </>
    );
};

export default AdsComponent;