const BASE = 'https://livconfessions-backend.onrender.com/';
// const BASE = 'http://127.0.0.1:8000/';

export const CONFESSIONS_URL = `${BASE}api/confession/`;
export const NEW_CONFESSIONS_URL = `${BASE}api/new/confessions/`;
export const TOP_CONFESSIONS_URL = `${BASE}api/top/confessions/`;
export const HOT_CONFESSIONS_URL = `${BASE}api/hot/confessions/`;
export const POST_URL = `${BASE}api/create/confession/`;
export const UPVOTE_URL = `${BASE}api/upvote/`;
export const DOWNVOTE_URL = `${BASE}api/downvote/`;
export const UPVOTED_URL = `${BASE}api/upvoted/`;
export const DOWNVOTED_URL = `${BASE}api/downvoted/`;
export const COMMENT_URL = `${BASE}api/comment/`;
export const COMMENT_DOWNVOTE_URL = `${BASE}api/comment/downvote/`;
export const COMMENT_UPVOTE_URL = `${BASE}api/comment/upvote/`;
export const COMMENT_DOWNVOTED_URL = `${BASE}api/comment/downvoted/`;
export const COMMENT_UPVOTED_URL = `${BASE}api/comment/upvoted/`;
export const IP_URL = `${BASE}api/ip/`;
export const USER_URL = `${BASE}api/user/`;